import React from 'react';

import { SEO } from 'components';

import Layout from 'layouts/base';
import TeamView from 'layouts/team';

const TeamPage = () => (
  <Layout>
    <SEO title='팀 소개' />
    <TeamView />
  </Layout>
);

export default TeamPage;
