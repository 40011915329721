import React from 'react';

import styled from '@emotion/styled';
import {
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

import { Heading, Body } from 'components';

const TeamHeader = styled.div`
  padding-top: 160px;
  margin-bottom: ${spacing.chapter}px;

  ${breakpoint(mediaQuery.mobile)} {
    padding-top: 200px;
  }
`;

const TeamHero = () => (
  <TeamHeader>
    <Heading>
      설리번 선생님을 만나보세요  🎉
    </Heading>
    <Body>
      코딩으로 자신만의 결과물을 만들어보면서, 스스로의 방향을 설정할 수 있도록
      <br />
      가장 가까운 곳에서, 가장 필요한 도움을 드립니다.
    </Body>
  </TeamHeader>
);

export default TeamHero;
