import React from 'react';

import styled from '@emotion/styled';
import { mediaQuery, breakpoint } from 'styles';

import { Button, Callout } from 'components';

import TeamHeader from './team-header';
import TeamList from './team-list';

const Container = styled.section`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 6%;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 0 8%;
  }
`;

const ApplyButton = Button.withComponent('a');

const TeamView = () => (
  <Container>
    <TeamHeader />
    <TeamList />
    <Callout
      title='설리번 선생님이 되어보고 싶나요? ✋'
      content={(
        <>
          누군가에게 만들고 싶은 것을 만드는 재미를 주고 싶은 분들을 찾고 있어요.
          <br />
          설리번 선생님을 모집하는 기간이 되면 알림을 드릴께요.
        </>
      )}
      action={(
        <ApplyButton
          href='https://slvn.page.link/pre-register-2020'
          target='_blank'
          rel='noreferrer noopener'
          type='primary'
          size='small'
          css={{ width: '140px' }}
          data-gtm='conversion.newSullivan'
        >
          알림 신청
        </ApplyButton>
      )}
    />
  </Container>
);

export default TeamView;
