import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import {
  Button,
  Card,
  CardContent,
  Callout,
  Icon,
  Image,
  Heading,
  Body,
  Caption,
} from 'components';

import { shuffle } from 'utils';
import { HOST, MEMBER } from 'constants';

const COUNT = 12;

const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.section / 4}px;

  ${breakpoint(mediaQuery.pablet)} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${spacing.section / 2}px;
  }

  ${breakpoint(mediaQuery.desktop)} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Row = styled.div`
  width: 100%;
  margin-top: ${spacing.section}px;
  margin-bottom: ${spacing.chapter * 3}px;
  text-align: center;

  ${Button} {
    width: 136px;
  }
`;

const cardContainerStyle = css`
  margin: ${spacing.chapter}px 0;
`;

const imageStyle = css`
  width: 100%;
  height: 140px;
  margin-bottom: ${spacing.row + spacing.leading}px;
  border: 1px solid ${colors.gray[20]};

  ${breakpoint(mediaQuery.desktop)} {
    height: 200px;
  }
`;

const bodyStyle = css`
  line-height: 1.88;
  font-weight: 500;
  color: ${colors.gray[80]};
`;

const TeamList = () => {
  const [list, setList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleClickLoadMore = useCallback(() => {
    setCurrentPage(prevCurrentPage => prevCurrentPage + 1);
  }, []);

  useEffect(() => {
    const updatedList = shuffle(Object.values(MEMBER));
    setList(updatedList);
  }, []);

  const currentList = useMemo(
    () => list.slice(0, (currentPage + 1) * COUNT),
    [list.length, currentPage],
  );

  const isLoaderVisible = useMemo(
    () => {
      if (!list.length) {
        return false;
      }

      const limit = Math.ceil(Object.keys(MEMBER).length / COUNT);
      return limit > currentPage + 1;
    },
    [list.length, currentPage],
  );

  return (
    <>
      <CardContainer
        css={cardContainerStyle}
      >
        {currentList.map(({
          name,
          image,
          introduce,
          githubID,
        }) => (
          <Card key={githubID}>
            <Image
              css={imageStyle}
              src={`${HOST.PROFILE}${image}`}
              alt={name}
            />
            <CardContent>
              <Body css={bodyStyle}>
                {name}
              </Body>
              <Caption>
                {introduce}
              </Caption>
            </CardContent>
          </Card>
        ))}
      </CardContainer>
      <Row>
        {isLoaderVisible && (
          <Button
            onClick={handleClickLoadMore}
          >
            더보기
          </Button>
        )}
      </Row>
    </>
  );
};

export default TeamList;